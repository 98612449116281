.container {
  width: 100vw;
  height: 100vh;
  background-color: #06141f;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;

  .inputWrapper {
    margin: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    > div {
      font-size: 2rem;
      color: #fff;
      display: flex;
      align-items: center;
      gap: 2rem;

      .dateInput {
        border: none;
        padding: 1rem;
        background-color: #fff;
      }
    }
  }
}

.tabContainer {
  position: fixed;
  left: 3rem;
  top: 2.6rem;
  color: #fff;
  display: flex;
  gap: 1rem;

  .tab {
    cursor: pointer;
    transition: all 0.3s ease-in;
  }
  .activeTab {
    border-bottom: 1px solid #fff;
  }
}

.inputContainer {
  display: flex;
  flex-direction: column;
  color: #fff;
  gap: 1rem;
  border: 1px solid #fff;
  border-radius: 0.4rem;
  padding: 2rem;
  label {
    font-size: 2rem;
  }
  input {
    outline: none;
    border: none;
    padding: 0.8rem;
    border-radius: 0.4rem;
    width: 20rem;
  }
  button {
    margin-top: 1rem;
    align-self: center;
  }
}
