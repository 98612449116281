.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  height: 100vh;
  height: 100svh;

  .heading {
    text-align: center;
    font-size: 1.5rem;
  }
  .countdown-container {
    font-size: 3rem;
    display: flex;
  }
}
