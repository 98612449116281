.overlayFixed {
  position: fixed;
  inset: 0;
  background-color: #18203396;
  backdrop-filter: blur(10px);
}

.overlayWrapper {
  position: fixed;
  inset: 15%;
  background-color: #182033;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 10rem;
  color: #fff;
  .heading {
    font-size: 2rem;
  }
  .controlsWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .controlBox {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
      .icon {
        width: 20rem;
        height: 10rem;
        > img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
  .progressBox {
    .progressBar {
      position: relative;
      border-radius: 10px;
      border: 1px solid #fff;
      height: 10px;
      width: 100%;
    }
  }
}

@media screen and (max-width: 992px) {
  .overlayWrapper {
    inset: 0;
    padding: 2rem 3rem;
    .controlsWrapper {
      justify-content: space-evenly;
      .controlBox {
        .icon {
          width: 10rem;
          height: 5rem;
        }
      }
    }
  }
}
