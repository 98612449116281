.modal360 {
  position: relative;
  background-color: var(--colorBlack);
  height: 100%;
  width: 100%;
  overflow: hidden;

  .zoomButtonContainer {
    position: fixed;
    left: 1%;
    top: 7rem;
    display: flex;
    border-radius: 10rem;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
    line-height: 0.7;
    background-color: var(--colorWhite);
    // transition: all 0.3s ease-in;

    .zoomButton {
      overflow: hidden;
      padding: 0 1rem;
      cursor: pointer;
      background-color: var(--colorWhite);
      color: var(--colorBlack);
      user-select: none;

      &:first-child {
        border-top-left-radius: inherit;
        border-bottom-left-radius: inherit;
        border-bottom: 5px solid transparent;
      }
      &:last-child {
        border-top-right-radius: inherit;
        border-bottom-right-radius: inherit;
      }
      // &:hover {
      //   background-color: var(--primaryColor);
      //   color: var(--colorWhite);
      // }
    }
  }
}
