.container {
  position: fixed;
  inset: 0;
  padding-top: env(safe-area-inset-top);
  padding-right: env(safe-area-inset-right);
  padding-left: env(safe-area-inset-left);
  padding-bottom: env(safe-area-inset-bottom);
  display: flex;
  align-items: flex-end;
  background: #192031;
  .closeBtn {
    cursor: pointer;
    position: fixed;
    z-index: 5;
    top: calc(env(safe-area-inset-top) + 2rem);
    right: 2rem;
  }

  .imgContainer {
    position: relative;
    bottom: -5rem;
    .bg {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }

    .leaf {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
      max-width: 10rem;
      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
        user-drag: none;
      }
      .message {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 40%;
        height: 20%;
        transform: translate(-50%, -50%);
        font-size: 6px;
        text-overflow: ellipsis;
        overflow: hidden;
        word-wrap: break-word;
        text-align: center;
      }
    }
  }
  .archiveButton {
    left: auto;
    right: 1rem;
    bottom: 1rem;
  }
}

@media screen and (max-width: 1600px) {
  .container {
    .imgContainer {
      .leaf {
        max-width: 6.5rem;
      }
    }
  }
}
@media screen and (max-width: 1400px) {
  .container {
    .imgContainer {
      bottom: -5rem;
      .leaf {
        max-width: 5.5rem;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .container {
    .imgContainer {
      bottom: -5rem;
      .leaf {
        max-width: 3.4rem;
      }
    }
  }
}

@media screen and (max-width: 667px) {
  .container {
    .imgContainer {
      bottom: -1rem;
    }
  }
}
