.RadialProgress {
  block-size: 10vmin;

  inline-size: 10vmin;
  min-inline-size: 100px;
  min-block-size: 100px;
  display: none;
  place-items: center;
  font-weight: 700;
  font-size: max(1vmin, 1.4rem);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  color: #fff;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 50%;
    z-index: -1;
    background: conic-gradient(
      hsl(var(--hue) 100% 70%),
      hsl(var(--hue) 100% 40%),
      hsl(var(--hue) 100% 70%) var(--progress, 0%),
      var(--track-bg) var(--progress, 0%) 100%
    );

    mask-image: radial-gradient(
      transparent var(--holesize),
      black calc(var(--holesize) + 0.5px)
    );
  }
}
@media screen and (max-width: 991px) {
  .RadialProgress {
    min-inline-size: 70px;
    min-block-size: 70px;
    font-size: max(1vmin, 1.2rem);
  }
}
