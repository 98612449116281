@import "../../../styles/_mixins.scss";

@include keyframes(fadeIn) {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0);
  }

  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}
@include keyframes(fadeOut) {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }

  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0);
  }
}

@include keyframes(modalFadeIn) {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@include keyframes(modalFadeOut) {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(0);
  }
}

.fadeIn {
  z-index: 1;
  @include animation("fadeIn 0.3s forwards");
}
.fadeOut {
  z-index: -1;
  @include animation("fadeOut 0.3s forwards");
}
.modalFadeIn {
  z-index: 1;
  @include animation("modalFadeIn 0.3s forwards");
}
.modalFadeOut {
  z-index: -1;
  @include animation("modalFadeOut 0.3s forwards");
}
.container {
  z-index: 4;
  position: fixed;
  inset: 0;
  padding-top: env(safe-area-inset-top);
  padding-right: env(safe-area-inset-right);
  padding-left: env(safe-area-inset-left);
  padding-bottom: env(safe-area-inset-bottom);
  background: rgba(13, 13, 13, 0.3);
  backdrop-filter: blur(10px);
  color: #fff;

  .leaf {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .greenLeaf {
    opacity: 0;
    margin: -10% 10%;
  }
  .yellowLeaf {
    margin: -5% -10%;
  }

  .nextButton {
    left: 50%;
    transform: translateX(-50%);
    padding: 1rem 3rem;
    bottom: 5rem;
  }
}
@media screen and (max-width: 1000px) {
  .container {
    .leaf {
      img {
        max-width: 4.5rem;
      }
    }
  }
}
