.MainScreenWrapper {
  background-image: url("../../assets/images/homeBgDesktop.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .sideVideo {
    width: 75vw;
    height: 100%;
  }
  .mainContainer {
    width: 35vw;
    height: 100%;
    background-image: url("../../assets/images/homeBg.jpg");
    display: flex;
    background-repeat: no-repeat;
    background-size: cover;
    align-items: center;
    justify-content: center;
    padding: 0 2rem;
    .titleContainer {
      width: 30rem;
      height: 30rem;
      > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      // display: flex;
      // flex-direction: column;
      // gap: 0.75rem;
      // align-items: center;
      // .titleWrapper {
      //   color: #fff;
      //   font-size: 3.75rem;
      //   text-transform: uppercase;
      //   letter-spacing: 0.1em;
      //   font-family: "Mogen";
      //   text-align: center;
      //   overflow: hidden;
      //   line-height: 1.2;
      //   letter-spacing: 0.6rem;
      //   .title {
      //     display: block;
      //     animation: reveal 700ms cubic-bezier(0.77, 0, 0.175, 1);
      //   }
      // }
    }

    .nextBtn {
      position: absolute;
      bottom: 12rem;
    }
  }
}

@media screen and (max-width: 992px) {
  .MainScreenWrapper {
    background: transparent;
    align-items: center;
    justify-content: center;
    .mainContainer {
      width: 100vw;
      .titleContainer {
        .titleWrapper {
          font-size: 3rem;
        }
      }
      .nextBtn {
        bottom: 3rem;
      }
    }
  }
}

@keyframes reveal {
  0% {
    transform: translate(0, 100%);
  }
  100% {
    transform: translate(0, 0);
  }
}
