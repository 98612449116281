.container {
  background: #ffffff;
  color: #05141f;
  outline: none;
  min-width: 9rem;
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  border-radius: 30px;
  font-size: 1.125rem;
  font-family: "Poppins";
  letter-spacing: 0.15rem;
  padding: 0.75rem 2.5rem;
  font-weight: 600;
  box-sizing: border-box;
  user-select: none;
  transition: all 0.3s ease-in-out;

  &:hover:not(:disabled) {
    text-decoration: underline;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: grey;
  }
}

@media only screen and (max-width: 992px) {
  .container {
    min-width: 8rem;
    height: 2.5rem;
    // height: 4rem;
    padding: 0.5rem 2rem;
  }
}
