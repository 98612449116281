.homeZone {
  background-color: rgba(24, 32, 51, 0.4509803922);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  bottom: 0;
  position: relative;
  width: 100%;
}

.closeList {
  transform: rotate(180deg);
  position: absolute;
  top: 2rem;
  z-index: 10;
  width: 3rem;
  height: 3rem;
  right: -1.5rem;
  > svg {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.zoneContainer {
  position: absolute;
  left: 0;
  top: 0;
  padding: 1rem 3rem;
  color: white;
  min-width: 10rem;
  z-index: 5;

  h2 {
    text-align: left;
    margin: 1.5rem 0;
    font-weight: 600;
    font-family: "Frank Ruhl Libre";
    letter-spacing: 0.1em;
    padding-left: 1rem;
    font-size: 3rem;
    border-bottom: 1px solid #fff;
  }

  .zonelist {
    font-size: 1.325rem;
    max-height: calc(100% - 5rem);
    overflow-y: auto;
    overflow-x: hidden;
    direction: rtl;

    ul {
      direction: ltr;
      padding-left: 0;
    }

    /* width */
    &::-webkit-scrollbar {
      width: 5px;
      display: none;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #03090d;
      border-radius: 8px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #15354b;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #23597c;
    }

    li {
      list-style-type: none;
      box-sizing: border-box;
      cursor: pointer;
      display: flex;
      align-items: center;
      font-family: "Lato";
      justify-content: space-between;
      opacity: 0.7;
      max-width: 20rem;
      padding: 0.85rem 0;
      padding-left: 1.5rem;
      margin-bottom: 1.5rem;
      > svg {
        transform: translate(0, 0.5rem);
        width: 2rem;
        height: 2rem;
        object-fit: contain;
      }
    }

    .selected {
      font-weight: 700;
      opacity: 1;
      border-radius: 50px;
      background: linear-gradient(
        177.12deg,
        rgba(255, 255, 255, 0.06) 2.39%,
        rgba(0, 0, 0, 0) 97.6%
      );
      backdrop-filter: blur(20px);
      position: relative;
      -webkit-box-shadow: -3px 18px 35px -19px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: -3px 18px 35px -19px rgba(0, 0, 0, 0.75);
      box-shadow: -3px 18px 35px -19px rgba(0, 0, 0, 0.75);
    }

    .selected::before {
      content: "\2022";
      color: white;
      position: absolute;
      left: -1rem;
      transform: scale(1.25);
    }

    //   li:hover {
    //     transform: scale(1.1);
    //   }

    //   li:active {
    //     transform: scale(0.95);
    //   }
  }

  .downArrow {
    width: 100%;
    display: none;
    justify-content: center;
    align-items: center;

    div {
      width: 0.75rem;
      height: 0.75rem;
      border-left: 2px solid;
      border-bottom: 2px solid;
      transform: rotate(-45deg);
    }
  }
}

@media screen and (max-width: 992px) and (orientation: landscape) {
  .zoneContainer {
    h2 {
      margin: 0;
      font-size: 2.5rem;
    }
    .zonelist {
      font-size: 1.2rem;
      //   min-height: 20vh;

      ul {
        //   padding-inline-start: 1.25rem;

        li {
          gap: 1rem;
          margin-bottom: 1rem;
          //   padding-left: 2.5rem;
        }
      }
    }

    .downArrow {
      display: flex;
    }
  }
}
