.LoginWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-image: url("../../assets/images/homeBgDesktop.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  .sideVideo {
    width: 75vw;
    height: 100%;
  }
  .loginContainer {
    display: flex;
    height: 100%;
    flex-direction: column;
    gap: 3rem;
    align-items: center;
    justify-content: center;
    width: 35vw;
    background-color: #202b47;
    padding: 2rem 3rem;
    .backBtn {
      width: 100%;
      margin-right: auto;
      height: 2rem;
      transform: translateX(-5%);
      margin-bottom: 2.5rem;
      display: none;
    }
    .loginContent {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;
      gap: 2.5rem;
      .title {
        color: #fff;
        width: 100%;
        letter-spacing: 0.1em;
        font-weight: 600;
        font-family: "Playfair Display";
        text-align: center;
        font-size: 4rem;
      }
      .inputWrapper {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 2rem;
        width: 70%;
        .inputBox {
          width: 100%;
          position: relative;
          .error {
            position: absolute;
            color: #ee3535;
            bottom: -55%;
            left: 2%;
            font-size: 0.8rem;
          }
          .input {
            width: 100%;
            color: #fff;
            background: transparent;
            border: 1px solid #fff;
            border-radius: 30px;
            padding: 0.75rem 1.25rem;
            &:focus {
              outline: none;
            }
          }
        }
      }
    }

    .bottomText {
      color: #fff;
      font-weight: 400;
      font-size: 1rem;
      margin-bottom: 1rem;
      > span {
        font-weight: 700;
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .LoginWrapper {
    background: transparent;
    align-items: center;
    justify-content: center;
    .loginContainer {
      width: 100vw;
      gap: 2rem;
      justify-content: space-between;
      background-color: #182033;
      .loginContent {
        gap: 2rem;
        .title {
          text-align: left;
          font-size: 3rem;
        }
        .inputWrapper {
          gap: 1.5rem;
          width: 100%;
          .inputBox {
            .error {
              font-size: 0.75rem;
            }
          }
        }
      }
      .backBtn {
        display: block;
      }
      .bottomText {
        font-size: 1rem;
      }
    }
  }
}
