.controls {
  .container {
    pointer-events: none;
  }

  position: relative;
  // width: 100vw;
  display: flex;
  justify-content: space-between;
  // height: 100vh;

  .container {
    pointer-events: none;
  }

  .controlsList {
    pointer-events: auto;
    position: fixed;
    right: 2rem;
    padding: 1.5rem 1rem;
    gap: 2.5rem;
    max-height: 20rem;
    transition: 0.25s height;
    top: calc(50% - 10rem);
    // width: rem;
    border-radius: 50px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .icon {
      cursor: pointer;
      position: relative;

      .iconsSvg {
        transform: scale(1.75);
      }

      .tutHolder {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 3.5rem;
        height: 3.5rem;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        // background-color: red;
        pointer-events: none;
      }
    }

    .circle {
      // width: 60px;
      // aspect-ratio: 1/1;
      // border-radius: 50%;
      // background-color: #05141f;
      // display: flex;
      // align-items: center;
      // justify-content: center;
      // user-select: none;
      // cursor: pointer;
      // transition: 0.25s;
      // position: relative;
      cursor: pointer;

      .lottiep {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 18rem;
        height: 18rem;
        transform: translate(-50%, -60%);
        background: transparent;
      }

      + .likeBtnDisbale {
        cursor: not-allowed;
        opacity: 0.75;
      }

      svg {
        transform: scale(1.4);
        width: 1.5rem;
        height: 1.5rem;
        position: relative;
        z-index: 2;
      }

      > img {
        transition: 0.25s;
        width: 50%;
      }

      transition: 0.25s;

      // &:hover {
      //   transform: scale(1.05);
      // }

      // &:active {
      //   transform: scale(0.75);
      // }

      // &.alreadyLike {
      //   background-color: #044bd3;
      // }
    }
  }

  .bottomMenu {
    pointer-events: auto;
    margin-right: 0.5rem;

    position: fixed;
    bottom: 1rem;
    right: 6rem;
    display: flex;
    // align-items: center;
    gap: 0.5rem;

    .rectangle {
      background-color: #fff;
      border-radius: 40px;
      color: #000;
      padding: 1.2rem 1.5rem;
      font-size: 1.25rem;
      display: flex;
      align-items: center;
      gap: 1rem;
      cursor: pointer;

      svg {
        transform: scale(2);
      }
    }

    .homeButton {
      background-color: #fff;
      height: 4rem;
      width: 4rem;
      border-radius: 50%;
      margin-right: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }

  .leftBottom {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 6;
    padding: 20px;

    .circle {
      width: 60px;
      aspect-ratio: 1/1;
      border-radius: 50%;
      background-color: #05141f;
      display: flex;
      align-items: center;
      justify-content: center;
      user-select: none;
      cursor: pointer;
      transition: 0.25s;
      position: relative;

      .lottiep {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 18rem;
        height: 18rem;
        transform: translate(-50%, -60%);
        background: transparent;
      }

      + .likeBtnDisbale {
        cursor: not-allowed;
        opacity: 0.75;
      }

      svg {
        width: 1.5rem;
        height: 1.5rem;
        position: relative;
        z-index: 2;
      }

      > img {
        transition: 0.25s;
        width: 50%;
      }

      transition: 0.25s;

      &:hover {
        transform: scale(1.05);
      }

      &:active {
        transform: scale(0.75);
      }

      &.alreadyLike {
        background-color: #044bd3;
      }
    }

    .qna {
      margin-bottom: 30px;
    }

    .row {
      display: flex;
      align-items: center;

      .rectangle {
        display: flex;
        align-items: center;
        background: #05141f;
        color: #fff;
        box-sizing: border-box;
        height: 60px;
        margin-left: 30px;
        background: rgba(5, 20, 31, 0.8);
        // box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
        backdrop-filter: blur(8px);
        -webkit-backdrop-filter: blur(8px);
        border: 1px solid rgba(255, 255, 255, 0.18);
        cursor: pointer;
        user-select: none;

        > img {
          width: 15%;
          aspect-ratio: 1/1;
          margin: 10px 20px;
        }

        > div {
          padding-right: 20px;
        }
      }
    }
  }

  .rightBottom {
    position: absolute;
    right: -130px;
    bottom: 0;
    z-index: 1;
    padding: 20px 0px;

    .rectangle {
      display: flex;
      align-items: center;
      color: #fff;
      box-sizing: border-box;
      height: 60px;
      background: rgba(5, 20, 31, 0.8);
      // box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
      backdrop-filter: blur(8px);
      -webkit-backdrop-filter: blur(8px);
      border: 1px solid rgba(255, 255, 255, 0.18);
      // transform: translateX(160px);
      margin-bottom: 20px;
      transition: all 0.5s ease;
      cursor: pointer;

      &:last-of-type {
        margin-bottom: 0;
      }

      &:hover {
        transform: translate(-128px, 0);
      }

      > img {
        width: 10%;
        aspect-ratio: 1/1;
        margin: 10px 20px;
      }

      > div {
        padding-right: 20px;
      }
    }
  }
}

.interest {
  background-color: #05141f;
  color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2rem;
  width: 40rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 1rem;

    .left {
      display: flex;
      align-items: center;

      > img {
        width: 94px;
      }

      .name {
        padding-left: 0.75rem;
        border-left: 1px solid #fff;
      }
    }

    .close {
      background: #fff;
      outline: none;
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      font-size: 10px;
      padding: 0;
      cursor: pointer;

      img {
        width: 50%;
        height: 50%;
        object-fit: contain;
      }
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-weight: 400;
    font-size: 1.3rem;
    gap: 2rem;

    span {
      font-weight: 600;
    }
  }

  .interestWrapper {
    > form {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;

      .formInputs {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 1rem;
        grid-column-gap: 2rem;
      }
    }

    .dropdown {
      > select {
        padding: 0.75rem;
        width: 100%;
        background-color: transparent;
        border: 1px solid #fff;
        border-radius: 4px;
        color: #fff;
        -webkit-appearance: none;
        outline: none;

        option {
          background-color: #05141f;
          color: #fff;
        }
      }
    }

    .inputWrapper {
      min-width: 17rem;
      border: 1px solid #fff;
      border-radius: 4px;

      > input {
        padding: 0.75rem;
        background: transparent;
        caret-color: #fff;
        color: #fff;
        outline: none;
        border: none;
        width: 100%;
        height: 100%;
      }
    }
  }

  .sendBtn {
    margin-top: 0.5rem;
  }

  .error {
    color: red;
    font-size: 0.75rem;
    margin: 0;
  }

  .carImage {
    width: 262px;
    margin: 30px 0;
  }

  .actionBtns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .btn {
      background: #fff;
      border: none;
      border-radius: 0;
      margin: 0 30px;
      padding: 10px 50px;
      box-sizing: border-box;
      width: calc(50% - 60px);
      text-transform: uppercase;
      font-weight: 600;
      font-size: 18px;
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 992px) and (orientation: landscape) {
  .controls {
    .container {
      pointer-events: none;
    }

    .controlsList {
      right: 0;
      padding: 1rem 0.5rem;
      gap: 0.5rem;
      top: calc(50% - 4.5rem);
      max-height: 9rem;
      margin: 0 max(1.5rem, env(safe-area-inset-right)) 0 0;

      .icon {
        .iconsSvg {
          transform: scale(1);
        }

        .tutHolder {
          width: 2.25rem;
          height: 2.25rem;
          // background-color: red;
        }
      }

      .circle {
        svg {
          transform: scale(1);
        }
      }
    }

    .bottomMenu {
      position: fixed;
      bottom: 1rem;
      right: 2rem;
      display: flex;
      align-items: center;
      gap: 0.5rem;

      margin: 0 max(1.5rem, env(safe-area-inset-right)) 0 0;

      .rectangle {
        background-color: #fff;
        border-radius: 40px;
        color: #000;
        padding: 0.5rem 1rem;
        display: flex;
        align-items: center;
        font-size: 1rem;
        gap: 0.5rem;

        svg {
          transform: scale(1.5);
        }
      }

      .homeButton {
        height: 2.1rem;
        width: 2.1rem;

        svg {
          height: 17px;
          width: 15px;
        }
      }
    }

    position: relative;
    // width: 100%;
    display: flex;
    justify-content: space-between;
    // height: 100vh;
    // height: calc(100vh - 100%);
    // height: -webkit-fill-available;

    .leftBottom {
      .row {
        .rectangle {
          height: 40px;
        }
      }
    }

    .rightBottom {
      position: absolute;
      right: 1rem;

      .rectangle {
        height: 40px;
      }
    }
  }

  .interest {
    width: 100%;
    height: 100%;
    justify-content: space-evenly;

    .info {
      > img {
        width: 6rem;
        height: 6rem;
        object-fit: contain;
      }

      gap: 1rem;
      font-size: 0.9rem;
    }

    .interestWrapper {
      width: 100%;

      .inputWrapper {
        min-width: 16rem;
      }
    }
  }
}

@media only screen and (max-width: 992px) and (orientation: portrait) {
  .interest {
    width: 100%;
    height: 100%;

    .interestWrapper {
      width: 100%;
      height: 100%;

      form {
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: stretch;

        .formInputs {
          grid-template-columns: repeat(1, 1fr);
        }
      }

      .inputWrapper {
        min-width: 16rem;
      }
    }
  }
}
