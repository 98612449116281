/* The side navigation menu */
.sidenav {
  height: 100%; /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 3; /* Stay on top */
  top: env(safe-area-inset-top); /* Stay at the top */
  right: env(safe-area-inset-right);
  bottom: env(safe-area-inset-bottom);
  overflow-x: hidden; /* Disable horizontal scroll */
  padding: 0;
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
  background-color: rgba(24, 32, 51, 0.7);
  box-shadow: -14px 0px 21px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(17px);
  color: #fff;
  overflow: hidden;
  .header {
    font-family: "Playfair Display";
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #fff;
    padding-bottom: 0.5rem;
    .heading {
      font-weight: 500;
      font-size: 1.3rem;
    }
    .closeBtn {
      cursor: pointer;
    }
  }
  .main {
    position: relative;
    margin-top: 2rem;
    height: 93%;
    overflow: auto;

    .noData {
      text-align: center;
      margin: 1rem 0;
    }
    /* width */
    &::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey;
      border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #fff;
      border-radius: 10px;
    }
    .card {
      margin-bottom: 1.5rem;
      padding-right: 0.5rem;

      .description {
        padding-left: 4rem;
        word-break: break-all;

        .expandBtn {
          cursor: pointer;
          opacity: 0.7;
        }
      }
    }
    .footer {
      margin-bottom: 1rem;
      width: 100%;
      button {
        position: relative;
        left: 50%;
        bottom: 0.5rem;
        transform: translateX(-50%);
      }
    }
  }
}
.upper {
  display: flex;
  gap: 1rem;
  .nameInitial {
    text-transform: uppercase;
    background: #192031;
    color: #fff;
    border: 1px solid #ffffff;
    border-radius: 50%;
    height: 3rem;
    width: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.3rem;
    font-weight: 600;
  }
  .nameContainer {
    .name {
      text-transform: capitalize;
    }
    .date {
      opacity: 0.7;
      font-size: 0.8rem;
    }
  }
}
.isOpen {
  width: 30rem;
  // padding: 2rem 2rem 2rem 2.5rem; /* Place content 60px from the top */
  padding-top: calc(env(safe-area-inset-top) + 2.5rem);
  padding-right: calc(env(safe-area-inset-right) + 2rem);
  padding-left: calc(env(safe-area-inset-left) + 2rem);
  padding-bottom: calc(env(safe-area-inset-bottom) + 2rem);
  top: 0;
  right: 0;
}

@media screen and (max-width: 991px) {
  .isOpen {
    width: 100%;
  }
}
