#rotate {
  z-index: 999999;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #fff;
  background: #05141f;
  // background: #05141f url(./assets/bg.png) no-repeat;
  // background-size: 100% 100%;

  .logo {
    position: absolute;
    top: 1rem;
    left: 2rem;
    max-width: 5rem;
  }

  .phone {
    // height: 50px;
    // width: 100px;
    // border: 3px solid #fff;
    // border-radius: 10px;
    animation: rotate 1.5s ease-in-out infinite alternate;
    /* display: none; */

    img {
      max-width: 8rem;
      object-fit: contain;
    }
  }

  .message {
    color: #fff;
    font-weight: 400;
    font-size: 1.2rem;
    margin-top: 2.2rem;
    width: 80%;
    text-align: center;
    /* display: none; */
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(-60deg);
  }

  100% {
    transform: rotate(-60deg);
  }
}

@media only screen and (max-width: 1024px) and (orientation: portrait) {
  .portraitRotate {
    display: flex !important;
  }

  .landscapeRotate {
    display: none;
  }
}

@media only screen and (max-width: 1024px) and (orientation: landscape) {
  .portraitRotate {
    display: none;
  }

  .landscapeRotate {
    display: flex !important;
  }
}
