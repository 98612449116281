.welcomeWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  background: url("../../assets/images/welcomeBg2.jpg");
  background-color: #202b47;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;

  .welcomeContent {
    display: flex;
    position: absolute;
    inset: 25%;
    background-color: #202b4765;
    backdrop-filter: blur(20px);
    flex-direction: column;
    padding: 2rem 4rem;
    gap: 2rem;
    align-items: center;
    justify-content: space-between;
    .speakerBox {
      // display: none;
      position: absolute;
      bottom: 2rem;
      right: 2rem;
      width: 2.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 2.5rem;
      > svg {
        object-fit: contain;
      }
      :active {
        transform: scale(0.9);
      }
    }
    .backBtn {
      width: 100%;
      margin-right: auto;
      height: 2rem;
      transform: translateX(-5%);
      display: none;
    }
    .welcomeBox {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      align-items: center;
      position: relative;

      .welcomeTitle {
        font-size: 2.6rem;
        font-family: "Playfair Display";
        font-weight: 600;
        letter-spacing: 0.1em;
        text-transform: capitalize;
        color: #fff;
      }
      .welcomeDesc {
        text-align: center;
        font-family: "Lato";
        font-size: 1.15rem;
        line-height: 1.6;
        color: #fff;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .welcomeWrapper {
    align-items: center;
    justify-content: space-between;
    background-position: 55% 55%;
    background-size: cover;
    .welcomeContent {
      inset: 10% 30%;
      padding: 2rem;
      justify-content: space-between;
      gap: unset;

      .welcomeBox {
        .welcomeTitle {
          text-align: left;
          font-size: 1.5rem;
        }
        .welcomeDesc {
          font-size: 0.9rem;
        }
      }
      .backBtn {
        display: block;
      }
    }
  }
}
