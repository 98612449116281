body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

.mainLoader{
  background-color: #192031;
  height: 100vh;
  height: 100svh;
  width: 100%;
}

textarea,
.treeButton {
  outline: none;
  border: none;
}
.treeButton {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #192031;
  color: #fff;
  padding: 1rem 1.5rem;
  border-radius: 6rem;
  border: 1px solid #fff;
  font-size: 1.3rem;
  cursor: pointer;
  transition: all 0.3s;
  display: flex;
  align-items: center;
}
.treeButton svg {
  margin-right: 0.5rem;
}
.treeButton:hover {
  background-color: #fff;
  color: #192031;
}
.treeButton:hover > svg > path {
  fill: #192031;
}

.treeButton:disabled {
  cursor: not-allowed;
  color: #fff;
  background-color: grey;
}
.treeButton:hover:is([disabled]) {
  color: #fff;
  background-color: grey;
}
.treeButton:hover:is([disabled]) > svg > path {
  fill: #fff;
}
