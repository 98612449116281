:root {
  --responsiveHeight: 100vh;
  --PhoneInputCountryFlag-height: 1rem !important;
  --PhoneInputCountrySelectArrow-color: #fff !important;
  --PhoneInputCountrySelectArrow-width: 0.5rem !important;
  --PhoneInputCountrySelectArrow-opacity: 1 !important;
  --PhoneInputCountrySelectArrow-marginLeft: -1rem !important;
  --hue: 220;
  --holesize: 65%;
  --track-bg: hsl(233 34% 92%);
}

body,
html {
  height: var(--responsiveHeight) !important;
  max-height: var(--responsiveHeight) !important;
  overflow: hidden;
  font-family: Poppins;
  margin: 0;
  padding: 0;
  /* padding: env(safe-area-inset-top) env(safe-area-inset-right)
    env(safe-area-inset-bottom) env(safe-area-inset-left); */
}

.modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(7, 7, 7, 0.479);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
select > option {
  background-color: #202b47 !important;
  color: #fff !important;
}

.selectUl {
  background-color: #202b47 !important;
  margin: 0;
  backdrop-filter: blur(20px) !important;
  position: relative;
  z-index: 110;
  padding: 0;
  list-style-type: none;
}
.selectOption {
  background-color: #202b47 !important;
  backdrop-filter: blur(20px) !important;
  padding: 0.5rem;
}

.PhoneInputInput {
  padding: 1rem;
  background-color: transparent !important;
  border: none;
  color: #fff !important;
}
.PhoneInputInput:focus {
  outline: none;
}

.responsiveHeight {
  height: var(--responsiveHeight) !important;
  max-height: var(--responsiveHeight) !important;
  overflow: hidden;
}
/* 
@media screen and (max-width: 992px) {
  :root {
    --PhoneInputCountrySelectArrow-marginLeft: 2rem !important;
  }
} */

@media only screen and (min--moz-device-pixel-ratio: 1.25),
  (-o-min-device-pixel-ratio: 5/4),
  (-webkit-min-device-pixel-ratio: 1.25),
  (min-device-pixel-ratio: 1.25) {
  body,
  html {
    font-size: 14px;
  }
}

@media only screen and (min--moz-device-pixel-ratio: 1.5),
  (-o-min-device-pixel-ratio: 3/2),
  (-webkit-min-device-pixel-ratio: 1.5),
  (min-device-pixel-ratio: 1.5) {
  body,
  html {
    font-size: 13px;
  }
}
