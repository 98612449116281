.overlayScreen {
  // position: fixed;
  // inset: 0;
  // z-index: 10;
  // opacity: 1;
  // animation: animatediv 1s ease forwards;
  // background-color: #182033;
}
.container {
  // TODO: change to 100%
  width: 100vw;
  height: 100vh;
  background-color: #182033;

  .spaceContainerParent {
    display: flex;
    justify-content: center;
    background-color: #182033;
    align-items: center;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);

    .spaceContainer {
      width: 75vw;
      height: calc(75vw / 1.77);
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      .spaceImage {
        width: 100%;
        height: 100%;
      }

      .bedug {
        width: 1rem;
        height: 1rem;
        transform: translate(-25%, -25%);
        margin: 0% 0%;
        background-color: red;
        position: absolute;
      }

      .markerHolder {
        position: absolute;
        margin: 0% 0%;
        transform: translate(-50%, -75%);
        top: 50%;
        left: 50%;

        svg {
          max-width: 3rem;
          max-height: 3rem;
          width: 5vw;
          height: 5vw;
        }
      }
    }
  }

  .zoneContainer {
    position: absolute;
    left: 0;
    top: 0;
    padding: 1rem 3rem;
    color: white;
    min-width: 10rem;
    z-index: 5;

    h2 {
      text-align: left;
      font-family: "Mogen";
      text-decoration: underline;
      letter-spacing: 0.1em;
      padding-left: 1rem;
      font-size: 3rem;
      font-weight: 400;
    }

    .zonelist {
      font-size: 1.25rem;
      max-height: calc(100vh - 15rem);
      overflow-y: auto;
      overflow-x: hidden;
      direction: rtl;

      ul {
        direction: ltr;
        padding-left: 0;
      }

      /* width */
      &::-webkit-scrollbar {
        width: 5px;
        display: none;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #03090d;
        border-radius: 8px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #15354b;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #23597c;
      }

      li {
        list-style-type: none;
        box-sizing: border-box;
        cursor: pointer;
        display: flex;
        align-items: center;
        font-family: "Lato";
        justify-content: space-between;
        opacity: 0.7;
        padding: 0.5rem 0;
        padding-left: 1.5rem;
        margin-bottom: 1.5rem;
        > svg {
          transform: translate(0, 0.5rem);
          width: 2rem;
          height: 2rem;
          object-fit: contain;
        }
      }

      .selected {
        font-weight: 700;
        opacity: 1;
        border-radius: 30px;
        background: linear-gradient(
          177.12deg,
          rgba(255, 255, 255, 0.06) 2.39%,
          rgba(0, 0, 0, 0) 97.6%
        );
        backdrop-filter: blur(20px);
        position: relative;
        -webkit-box-shadow: -3px 18px 35px -19px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: -3px 18px 35px -19px rgba(0, 0, 0, 0.75);
        box-shadow: -3px 18px 35px -19px rgba(0, 0, 0, 0.75);
      }

      .selected::before {
        content: "\2022";
        color: white;
        position: absolute;
        left: -1rem;
        transform: scale(1.25);
      }

      //   li:hover {
      //     transform: scale(1.1);
      //   }

      //   li:active {
      //     transform: scale(0.95);
      //   }
    }

    .downArrow {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      div {
        width: 0.75rem;
        height: 0.75rem;
        border-left: 2px solid;
        border-bottom: 2px solid;
        transform: rotate(-45deg);
      }
    }
  }

  .btn {
    position: absolute;
    bottom: 1rem;
    right: 1rem;

    button {
      margin: 1.25rem;

      svg {
        width: 0.4rem;
        height: 0.4rem;
        margin-right: 0.25rem;
      }
    }
  }
}

@media only screen and (max-width: 992px) and (orientation: landscape) {
  .container {
    width: 100%;
    height: 100%;
    // height: calc(100vh - 100%);

    .btnIos {
      button {
        bottom: 0;
        position: relative;
        right: 0;
      }
    }

    .btnAndroid {
      button {
        bottom: 0;
        position: relative;
        right: 0;
      }
    }

    .spaceContainerParent {
      .spaceContainer {
        width: 55vw;
        height: calc(55vw / 1.77);
        margin-bottom: 2rem;

        .markerHolder {
          svg {
            max-width: 2.5rem;
            max-height: 2.5rem;
            width: 3.5vw;
            height: 3.5vw;
          }
        }
      }
    }

    .zoneContainer {
      bottom: 0;
      top: 0;

      h2 {
        font-size: 2rem;
        text-align: left;
        margin: 0;
        padding: 0;
        padding-left: 2.5rem;
      }

      .zonelist {
        font-size: 0.9rem;
        min-height: 60vh;

        ul {
          //   padding-inline-start: 1.25rem;

          li {
            gap: 1rem;
            margin-bottom: 1rem;
            padding-left: 2.5rem;
          }
        }
      }
    }
  }
}

@keyframes animatediv {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
