@import "../../../styles/_mixins.scss";

@include keyframes(fadeIn) {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}
@include keyframes(fadeOut) {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}
@include keyframes(fadeInOpacity) {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@include keyframes(fadeOutOpacity) {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.modalFadeIn {
  z-index: 1;
  @include animation("fadeInOpacity 0.3s");
}
.modalFadeOut {
  z-index: -1;
  @include animation("fadeOutOpacity 0.3s forwards");
}

.formFadeIn {
  @include animation("fadeIn 1s");
}
.formFadeOut {
  @include animation("fadeOut 1s forwards");
}
.container {
  background: rgba(24, 32, 51, 0.7);
  backdrop-filter: blur(12px);
  position: fixed;
  inset: 0;
  padding-top: env(safe-area-inset-top);
  padding-right: env(safe-area-inset-right);
  padding-left: env(safe-area-inset-left);
  padding-bottom: env(safe-area-inset-bottom);
  display: flex;
  align-items: center;
  justify-content: center;

  form {
    height: 90vh;
    width: 70%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .closeBtn {
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
    }
    .leafImg {
      height: 60%;
    }
    .textContainer {
      position: absolute;
      height: 18rem;
      width: 20rem;
      left: calc(100% - 65%);
      font-weight: 600;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      textarea {
        background: transparent;
        resize: none;
        font-size: 1.1rem;
        color: #000;
        font-weight: 600;
        width: 100%;
        /* Hide scrollbar for Chrome, Safari and Opera */
        &::-webkit-scrollbar {
          display: none;
        }

        /* Hide scrollbar for IE, Edge and Firefox */

        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */

        &:disabled {
          opacity: 1;
          color: #000;
          -webkit-text-fill-color: #000;
        }
      }
    }
    button {
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

@media screen and (max-width: 1300px) {
  .container {
    form {
      .textContainer {
        left: calc(100% - 48rem);
      }
    }
  }
}

@media screen and (max-width: 1000px) and (orientation: landscape) {
  .container {
    form {
      height: 100%;
      .leafImg {
        height: 90%;
      }
      .textContainer {
        height: 48%;
        width: 38%;
        left: calc(100% - 75%);
      }
    }
  }
}
