.registerWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-image: url("../../assets/images/homeBgDesktop.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  height: 100vh;
  .sideVideo {
    width: 75vw;
    height: 100%;
  }
  .registerContainer {
    width: 35vw;
    height: 100%;
    background-color: #202b47;
    padding: 2rem 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    .backBtn {
      width: 100%;
      margin-right: auto;
      height: 2rem;
      transform: translateX(-5%);
      display: none;
    }
    .registerContent {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;
      gap: 1rem;
      .title {
        color: #fff;
        letter-spacing: 0.1em;
        width: 100%;
        font-weight: 600;
        text-align: center;
        font-family: "Playfair Display";
        font-size: 4.5rem;
      }
      .inputWrapper {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 2rem;
        margin-bottom: 2rem;
        width: 70%;
        .inputBox {
          width: 100%;
          position: relative;
          .error {
            position: absolute;
            color: #ee3535;
            bottom: -55%;
            left: 2%;
            font-size: 0.8rem;
          }
          select {
            padding-right: 1rem;
          }
          .input {
            width: 100%;
            color: #fff;
            background: transparent;
            border: 1px solid #fff;
            border-radius: 30px;
            padding: 0.75rem 1.25rem;
            &:focus {
              outline: none;
            }
          }
        }
        .selectBoxWrappers {
          position: relative;
          width: 100%;
          .error {
            position: absolute;
            color: #ee3535;
            bottom: -45%;
            left: 2%;
            font-size: 0.8rem;
          }
          .inputSelectBox {
            width: 100%;
            position: relative;
            border: 1px solid #fff;
            border-radius: 30px;
            padding: 0.75rem 1.25rem;
            select {
              min-width: 100%;
              max-width: 100%;
              padding-right: 1rem;
              border: none;
              background-color: transparent;
              color: #8e8e8e;
              &:focus {
                border: none;
                outline: none;
              }
              &:selection {
                color: #fff;
              }
            }
          }
        }

        .phoneInputBox {
          width: 100%;
          border: 1px solid #fff;
          padding: 0 1.5rem;
          border-radius: 30px;
          position: relative;
          .countryCode {
            position: absolute;
            top: 0.5rem;
            bottom: 0.5rem;
            display: flex;
            width: 2.5rem;

            align-items: center;
            justify-content: center;

            font-size: 0.9rem;
            box-sizing: content-box;
            color: #fff;
            // width: 2.5rem;
            // padding: 0 1rem;
            left: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            // transform: translateX(-0.5rem);s
            background-color: #202b47;
            z-index: 100;
            pointer-events: none;
            .dropdown {
              width: 1rem;
              height: 1rem;
              > svg {
                width: 100%;
                object-fit: contain;
                transform: translate(-10%, -30%);
                height: 100%;
                object-fit: contain;
              }
            }
          }
          .error {
            position: absolute;
            color: #ee3535;
            bottom: -55%;
            left: 2%;
            font-size: 0.8rem;
          }
        }
      }
    }

    .bottomText {
      color: #fff;
      font-weight: 400;
      font-size: 1rem;
      margin-bottom: 1rem;
      > span {
        font-weight: 700;
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .registerWrapper {
    background: transparent;
    align-items: center;
    justify-content: center;
    .registerContainer {
      padding: 0 3rem;
      width: 100vw;
      justify-content: space-between;
      background-color: #182033;
      .registerContent {
        .title {
          text-align: left;
          font-size: 4rem;
        }
        .inputWrapper {
          gap: 2.25rem;
          width: 100%;
          .inputBox {
            .error {
              font-size: 0.9rem;
            }
          }
          .phoneInputBox {
            width: 100%;
            border: 1px solid #fff;
            padding: 0 1.5rem;
            padding-left: 2.5rem;
            border-radius: 30px;
            position: relative;
            .countryCode {
              top: 50%;

              transform: translateY(-50%);
              font-size: 1.2rem;
              width: 3rem;

              left: 1.25rem;
              height: unset;
              bottom: 1px;
              background-color: #182033;
              .dropdown {
                width: 1rem;
                height: 1rem;
                > img {
                  width: 100%;
                  object-fit: contain;
                  transform: translate(-0%, -10%);
                  height: 100%;
                  object-fit: contain;
                }
              }
            }

            .error {
              font-size: 0.9rem;
            }
          }
        }
      }
      .backBtn {
        transform: translate(-5%, 50%);
        display: block;
      }
      .bottomText {
        font-size: 1rem;
      }
    }
  }
}
