.iframe {
  position: fixed;
  inset: 10%;
  width: 80%;
  height: 80%;
  z-index: 90;
  background-image: url("../../assets/gif/loader.gif");
  background-size: 10rem;
  background-repeat: no-repeat;
  background-color: #141a29;
  background-position: center;
}
.closeIframe {
  width: 2rem;
  height: 2rem;
  position: fixed;
  z-index: 92;
  top: 12%;
  right: 12%;
  cursor: pointer;
  > svg {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.iframeOverlay {
  position: fixed;
  inset: 0;
  backdrop-filter: blur(20px);
  z-index: 85;
}
.modal3DContainer {
  position: fixed;
  inset: 0;
  z-index: 50;
  background-image: url("../../assets/images/modalBg.jpg");
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  //   position: relative;
  .info {
    position: fixed;
    display: none;
    right: 5rem;
    bottom: 5rem;
    width: 2rem;
    height: 2rem;
    z-index: 45;
    cursor: pointer;
    > svg {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .modalTutOverlay {
    position: fixed;
    inset: 0;
    z-index: 48;
    backdrop-filter: blur(10px);
  }
  .modalTut {
    position: fixed;
    inset: 20%;
    z-index: 50;
    > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: top;
    }
    .tutClose {
      position: absolute;
      top: 2rem;
      right: 2rem;
      cursor: pointer;
    }
  }
  .modalDesc {
    position: absolute;
    color: #fff;
    top: 50%;
    transform: translateY(-50%);
    left: 5rem;
    width: 26rem;
    padding: 3rem 2rem;
    // height: 30rem;
    background-color: #141a29;
    backdrop-filter: blur(20px);
    -webkit-box-shadow: 1px 0.5px 1px 0px rgba(245, 245, 245, 1);
    -moz-box-shadow: 1px 0.5px 1px 0px rgba(245, 245, 245, 1);
    box-shadow: 1px 0.5px 1px 0px rgba(245, 245, 245, 1);
    z-index: 45;
    font-size: 1.25rem;
    -moz-user-select: none; /* firefox */
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE*/
    user-select: none; /* Standard syntax */
    border-radius: 10px;
    > svg {
      display: none;
      position: absolute;
      z-index: 52;
      cursor: pointer;
      right: 2rem;
      width: 2rem;
      height: 2rem;
      top: 2rem;
    }
    .title {
      font-family: "Lora";
      font-weight: 600;
      font-size: 1.5rem;
      padding-bottom: 1.5rem;
      border-bottom: 1px solid #fff;
    }
    .accNo {
      margin-top: 1rem;
      span {
        font-weight: 600;
      }
    }
    .bolderObject {
      font-weight: 600;
    }
  }
  .modalDescImg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10rem;
    width: 30rem;
    height: 30rem;
    // -moz-user-select: none; /* firefox */
    // -webkit-user-select: none; /* Safari */
    // -ms-user-select: none; /* IE*/
    // user-select: none; /* Standard syntax */

    z-index: 50;
    > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: top;
    }
    > svg {
      position: absolute;
      z-index: 52;
      cursor: pointer;
      right: 2rem;
      width: 2rem;
      height: 2rem;
      top: 2rem;
    }
  }
  .close {
    position: absolute;
    z-index: 12;
    width: 2rem;
    height: 2rem;
    top: 2rem;
    right: 2rem;
    cursor: pointer;
    > svg {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

@media screen and (max-width: 992px) and (orientation: "landscape") {
  .modal3DContainer {
    .modalTut {
      position: fixed;
      inset: 0;
      z-index: 50;

      .tutClose {
        position: absolute;
        top: 2rem;
        right: 2rem;
        cursor: pointer;
      }
    }
    .modalDesc {
      left: 2.5rem;
      width: 16rem;
      padding: 1rem;
      font-size: 1rem;
      > svg {
        display: none;
        right: 1rem;
        width: 1rem;
        height: 1rem;
        top: 1rem;
      }

      .title {
        font-size: 1.25rem;
      }
    }
    .modalDescImg {
      left: 5rem;
      width: 20rem;
      height: 20rem;
      > svg {
        right: 1rem;
        width: 1rem;
        height: 1rem;
        top: 1rem;
      }
    }
  }
}
