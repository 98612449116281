.container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #000;

  video {
    // width: 120vw;
    // height: calc(120vw / 1.77);
    width: 100vw;
    height: 100%;
    position: relative;
    z-index: 1;
    object-fit: cover;
  }

  .loader {
    width: 2rem;
    height: 2rem;
    position: absolute;
  }

  .infoText {
    position: absolute;
    top: 2rem;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    padding: 1rem;
    font-size: 1.25rem;
    font-weight: bold;
    text-decoration: underline;
    color: white;
    cursor: pointer;
  }

  .btn {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 2;

    button {
      margin: 1.25rem;

      svg {
        width: 0.6rem;
        height: 0.6rem;
        margin-right: 0.25rem;
      }
    }
  }
}

@media only screen and (max-width: 992px) and (orientation: landscape) {
  .container {
    .btn {
      right: 0;
    }

    .btnIos {
      bottom: 2rem;
    }

    .btnAndroid {
      bottom: 0;
    }
  }
}
