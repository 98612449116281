.headerWrapper {
  .zonesIcon {
    position: fixed;
    left: 3rem;
    top: 2rem;
    cursor: pointer;
    .zoneBox {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      width: 30vw;
    }
  }
  .menuIcon {
    position: fixed;
    right: 3rem;
    top: 2rem;
    > svg {
      cursor: pointer;
    }
    .menuBox {
      position: fixed;
      right: 0;
      top: 0;
      bottom: 0;
      width: 25vw;
      background-color: #18203373;
      backdrop-filter: blur(20px);
      .close {
        position: fixed;
        top: 1rem;
        right: 1rem;
        cursor: pointer;
      }
      .userDetails {
        margin-top: 3rem;
        display: flex;
        align-items: center;
        gap: 1rem;
        justify-content: flex-start;
        padding: 1rem 3rem;
        color: #fff;
        .userInitial {
          min-width: 3rem;
          height: 3rem;
          border-radius: 50%;
          background-color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 2rem;
          color: #000;
        }
        .NameWrapper {
          .name {
            text-transform: capitalize;
            font-weight: 600;
          }
        }
      }
      .menuList {
        margin-top: 2rem;
        display: flex;
        align-items: center;
        gap: 2rem;
        flex-direction: column;
        color: #fff;
        padding: 0 3rem;
        .menuItem {
          cursor: pointer;
          max-width: 100%;
          width: 100%;
          padding-bottom: 1.5rem;
          border-bottom: 1px solid #fff;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 1.5rem;
          .svgIcon {
            width: 2rem;
            height: 2rem;
            > svg {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 992px) and (orientation: "landscape") {
  .headerWrapper {
    .menuIcon {
      .menuBox {
        width: 35vw;
        .userDetails {
          margin-top: 1.5rem;
          max-width: 90%;
          padding: 0.5rem 1rem;
        }
      }
    }
  }
}
